'use client';
import {ChangeEvent, FC, FormEvent, Fragment, useRef, useState} from 'react';
import styles from './WaitlistPopupContainer.module.scss';
import {useOnClickOutside} from '@/helpers/hooks';
import close_icon from '../../app/assets/icons/close_2.svg';
import Image from 'next/image';
import {Dialog, Transition} from '@headlessui/react';
import promo from '../../app/assets/images/organizer_popup_promo.png';
import InputComponent from "@/shared/InputComponent";
import ButtonElement from "@/shared/ButtonElement";
import Calendar from "@/app/assets/icons/calendar.svg";
import Notification from "@/app/assets/icons/notification.svg";
import Telegram from "@/app/assets/icons/telegram.svg";
import Link from "next/link";
import {useTranslations} from "next-intl";
import addOrganizerWaitlist from "@/app/requests/addOrganizerWaitlist";

interface PopupContainerProps {
  className?: string;
  isOpen?: boolean;
  setIsOpen: (value: boolean) => void;
}

const WaitlistPopupContainer: FC<PopupContainerProps> =
  ({
     className = '',
     isOpen = false,
     setIsOpen,
   }) => {
    const t = useTranslations('WAITLIST_POPUP');
    const ref = useRef<HTMLDivElement>(null);
    useOnClickOutside(ref, () => close());

    const [name, setName] = useState<string>('');
    const [contacts, setContacts] = useState<string>('');
    const canSubmit = name.length > 0 && contacts.length >= 3;

    const [formSent, setFormSent] = useState<boolean>(false);

    const close = () => {
      if (setIsOpen)
        setIsOpen(false);
    }

    const handleContactsChange = (e: ChangeEvent<HTMLInputElement>) => {
      setContacts(e.target.value);
    };

    const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
      setName(e.target.value);
    }

    const handleFormSubmit = (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      if (!canSubmit)
        return

      addOrganizerWaitlist(name, contacts).then(() => {
        setFormSent(true);
      }).catch((error) => {
        console.error('Failed save organizer', error);
      });
    }

    return (
      <Transition
        appear={true}
        show={isOpen}>
        <Dialog as="div" onClose={close}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className={`${styles.backdrop}`}/>
          </Transition.Child>
          <div className={"fixed inset-0 overflow-y-auto full-width " + styles.root}>
            <div className="flex min-h-full items-center justify-center p-4 text-center full-width">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className={"full-width"}>
                  <div ref={ref} className={styles.popup__block}>
                    <div className={styles.popup__bg}/>
                    <button onClick={() => close()} className={styles.popup__close} aria-label="close">
                      <Image src={close_icon} alt="close" width={24} height={24}/>
                    </button>
                    <div className={styles.popup__promo}>
                      <h2>{t('promo.title')}</h2>
                      <p>{t('promo.text')}</p>
                      <Image src={promo} alt={"promo"} width={420} height={380}/>
                    </div>
                    <div className={styles.popup__form}>
                      <div className={styles.popup__form__icon}>
                        {
                          formSent
                            ? <Image width={48} height={48} src={Notification} alt="form-icon"/>
                            : <Image width={48} height={48} src={Calendar} alt="form-icon"/>
                        }
                      </div>

                      {
                        formSent && (
                          <>
                            <div className={styles.popup__form__heading}>
                              <h2>{t('form_sent.title')}</h2>
                              <p>{t('form_sent.text')}</p>
                            </div>
                            <ButtonElement
                              onClick={() => setIsOpen(false)}
                              type="button"
                              text={t('sounds_great')}>
                              {t('sounds_great')}
                            </ButtonElement>
                          </>
                        )
                      }

                      {!formSent && (<>
                          <div className={styles.popup__form__heading}>
                            <h2>{t('form.title')}</h2>
                            <p>{t('form.text')}</p>
                          </div>
                          <form onSubmit={handleFormSubmit}>
                            <InputComponent
                              type="text"
                              autoComplete="name"
                              className={`${styles.input} mt24`}
                              label={t('form.name_label')}
                              placeholder={t('form.name_placeholder')}
                              onChange={handleNameChange}
                            />
                            <InputComponent
                              type="text"
                              autoComplete="contacts"
                              label={t('form.contacts_label')}
                              placeholder={t('form.contacts_placeholder')}
                              onChange={handleContactsChange}
                            />
                            <div className={styles.popup__form__actions}>
                              <ButtonElement
                                type="button"
                                text={t('contact_support')}
                                className={styles.popup__form__support}
                                color={"dark"}
                                href={"https://t.me/m/dcA4ODRTZTMy"}
                                blank={true}
                              >
                                <Image src={Telegram} alt={"telegram"} width={24} height={24}/>{t('contact_support')}
                              </ButtonElement>
                              <ButtonElement
                                onClick={handleFormSubmit}
                                type="submit"
                                text={t('join_waitlist')}
                                disabled={!canSubmit}>
                                {t('join_waitlist')}
                              </ButtonElement>
                            </div>
                          </form>

                          <p className={styles.popup__form__notion}>
                            {t('by')} <Link href={"/public-offer"} target={"_blank"}>{t('public_offer')}</Link>
                          </p>
                        </>
                      )}

                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    );
  };

export default WaitlistPopupContainer;
