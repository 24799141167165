'use client';
import {FC, useState} from 'react';
import styles from './FooterComponent.module.scss';
import Link from 'next-intl/link';
import Letter from '../../app/assets/icons/letter.svg';
import Twitter from '../../app/assets/icons/twitter.svg';
import Telegram from '../../app/assets/icons/telegram-2.svg';
import Image from 'next/image';
import Mountain from '../../app/assets/icons/mountain.svg';
import { useTranslations } from 'next-intl';
import WaitlistPopupContainer from "@/shared/WaitlistPopupContainer";
import ButtonComponent from "@/shared/ButtonComponent";
import ButtonElement from "@/shared/ButtonElement";
interface FooterComponentProps {
  className?: string;
}

const FooterComponent: FC<FooterComponentProps> = ({ className = '' }) => {
  const t = useTranslations('FOOTER_COMPONENT');
  const year = new Date().getFullYear();
  const [waitlistPopupOpen, setWaitlistPopupOpen] = useState<boolean>(false);

  return (
    <>
    <footer className={`${styles.root} ${className}`}>
      <div className={`${styles.column}  container`}>
        <div className={styles.footer__container}>
          <ButtonElement
            className={styles.footer__organizer_button}
            text={t('room')}
            color={'dark'}
            onClick={() => setWaitlistPopupOpen(true)}
          >
            <Image src={Mountain} alt="write to us" />
            {t('room')}
          </ButtonElement>

          {/*<nav className={styles.navigaton}>*/}
          {/*  <Link className={styles.navigaton__link} href="/">*/}
          {/*    {t('about')}*/}
          {/*  </Link>*/}
          {/*  <Link className={styles.navigaton__link} href="/">*/}
          {/*    {t('organizers')}*/}
          {/*  </Link>*/}
          {/*  <Link className={styles.navigaton__link} href="/">*/}
          {/*    {t('client')}*/}
          {/*  </Link>*/}
          {/*  <Link className={styles.navigaton__link} href="/">*/}
          {/*    {t('refund')}*/}
          {/*  </Link>*/}
          {/*  <Link className={styles.navigaton__link} href="/">*/}
          {/*    {t('terms')}*/}
          {/*  </Link>*/}
          {/*</nav>*/}

          <div className={styles.social}>
            <a
              className={`${styles.social__link} ${styles.social__letter}`}
              target="_blank"
              href="mailto:support@gligle.app">
              <Image src={Letter} alt="Letter" />
            </a>
            <a className={styles.social__link} target="_blank" href="https://t.me/gligleapp">
              <Image src={Telegram} alt="telegram" />
            </a>
            <a className={styles.social__link} target="_blank" href="https://twitter.com/gligleapp">
              <Image src={Twitter} alt="Twitter" />
            </a>
          </div>
        </div>

        <b className={styles.text__bold}>Gligle, 2022-{year}</b>

        <p className={styles.text__default}>
          {t('use')}<br/>
          <Link href={'/public-offer'}>{t('public_offer')}</Link>
        </p>
      </div>
    </footer>
    <WaitlistPopupContainer
      isOpen={waitlistPopupOpen}
      setIsOpen={setWaitlistPopupOpen}
    />
    </>
  );
};

export default FooterComponent;
